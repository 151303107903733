import React from 'react'
import { FaPaperPlane } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { CiTwitter } from "react-icons/ci";



import logo from "../../assets/beyondtechsnobglogo.png"
function Footer() {
  return (
    <>
      <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fa fa-map-marker" />
            <div className="cta-text">
              <h4>Find us</h4>
              <a href="https://goo.gl/maps/Kad3z7VnW3erzccM9" target="_blank">
                <span> Malad east, Mumbai, Maharashtra 400097</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fa fa-phone" />
            <div className="cta-text">
              <h4>Call us</h4>
              <a href="tel:+9167300235" target="_blank">
                <span>+91 9167300235</span>
              </a>
              <a href="tel:+9167300235" target="_blank">
                <span>+91 7208693598</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fa fa-envelope-open" />
            <div className="cta-text">
              <h4>Mail us</h4>
              <a href="Beyondtechs.com" target="_blank">
                <span>Beyondtechs.com</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-lg-4 mb-50">
          <div className="footer-widget">
            <div className="footer-logo">
              <a href="index.html">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="logo"
                />
              </a>
            </div>
            <div className="footer-text">
              <p>
              Beyondtechs  Digital is a fully fledged Software Solutions Network. Initiated in the year 2023 and located in Mumbai,
                Beyondtechs, supports the concept of Digital India.
              </p>
            </div>
            <div className="footer-social-icon">
              <span>Follow us</span>
              {/* <a href="https://www.facebook.com/shree.krishnadigitalmarketing/"> */}
              <a>
                <i className="fa fa-facebook facebook-bg" ><FaFacebookF /></i>
              </a>
              {/* <a href="https://www.linkedin.com/company/shree-krishna-digital-marketing/?originalSubdomain=in"> */}
              <a>
                <i className="fa fa-linkedin twitter-bg" ><CiTwitter /></i>
              </a>
              {/* <a href="https://www.instagram.com/shree.krishnadigitalmarketing/"> */}
              <a>
                <i className="fa fa-instagram google-bg" ><FaInstagram /></i>
              </a>
              <a href="https://wa.me/+9167300235">
              <i className="fa fa-whatsapp watsapp-bg"><FaWhatsapp /></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/best-website-development-company-in-andheri">
                  Website Development
                </a>
              </li>
              {/* <li>
                <a href="/best-website-development-company-in-andheri">
                   Development
                </a>
              </li> */}
              <li>
                <a href="/best-digital-marketing-agency-in-mumbai">
                  Digital Marketing
                </a>
              </li>
             
              <li>
                <a href="/social-media-marketing-in-andheri-mumbai">
                  Social Media Marketing
                </a>
              </li>
              <li>
                <a href="/seo-agency-in-andheri-mumbai">SEO Servives</a>
              </li>
              <li>
                <a href="/best-graphic-design-agency-in-mumbai">
                  Graphic Design
                </a>
              </li>
              {/* <li>
                <a href="/ppc-management-company-in-andheri">PPC Marketing</a>
              </li> */}
              <li>
                <a href="/digital-marketing-blog">Blogs</a>
              </li>
              {/* <li>
                <a href="/best-graphic-design-agency-in-mumbai">
                  Graphic Design
                </a>
              </li> */}
              <li>
                <a href="/careers-in-digital-marketing-andheri">
                  Career Growth
                </a>
              </li>
              <li>
                <a href="/about-us-digital-marketing-company-andheri-mumbai">
                  About
                </a>
              </li>
              <li>
                <a href="/contact-us-digital-marketing">Contact</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Subscribe</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
                Don’t miss to subscribe to our new feeds, kindly fill the form
                below.
              </p>
            </div>
            <div className="subscribe-form">
              <form action="#">
                <input type="text" placeholder="Email Address" />
                <button>
                  <i ><FaPaperPlane /></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
          <div className="copyright-text">
            <p>
              Copyright © 2023, All Right Reserved{" "}
              <a href="/">Beyondtechs Software Solutions & Digital Marketing</a>
            </p>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
          <div className="footer-menu">
            <ul>
              <li>
                <a href="/best-digital-marketing-agency-in-mumbai">
                  Digital Marketing
                </a>
              </li>
              <li>
                <a href="/best-website-development-company-in-andheri">
                  Website Development
                </a>
              </li>
              <li>
                <a href="/social-media-marketing-in-andheri-mumbai">
                  Social Media Marketing
                </a>
              </li>
              <li>
                <a href="/seo-agency-in-andheri-mumbai">SEO Servives</a>
              </li>
              <li>
                <a href="/best-graphic-design-agency-in-mumbai">
                  Graphic Design
                </a>
              </li>
              {/* <li>
                <a href="/ppc-management-company-in-andheri">PPC Marketing</a>
              </li> */}
              {/* <li>
                <a href="/best-graphic-design-agency-in-mumbai">
                  Graphic Design
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    </>
  )
}

export default Footer
