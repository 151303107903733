import React from 'react'
import "./HomeSection.css"
function HomeSection() {
  return (
    <>
      <div className="home" id="homeSection">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-md-5 col-sm-5">
        <div className="homeTitle">
          <h1>The Best Software Solutions & Digital Marketing Agency In Malad, Mumbai.</h1>
          <p>
            Lift your business to new heights with us. Start With A Plan And
            Finish With Best Results Best Digital Marketing Agency In Malad.
          </p>
        </div>
      </div>
      <div className="col-md-7" />
    </div>
  </div>
</div>

    </>
  )
}

export default HomeSection
