import './App.css';
import Footer from './Components/Footer/Footer';
import Navigatio from './Components/Header/Navigatio';
import HomeSection from './Components/HomeSection/HomeSection';
import Process from './Components/Process/Process';
import Section from './Components/Section/Section';
import Testimonialtwo from './Components/Testimonial2/Testimonialtwo';

function App() {
  return (
   <>
 <Navigatio/>
   <HomeSection/>
   <Section/>
   <Testimonialtwo/>
   <Process/>
   <Footer/>
   </>
  );
}

export default App;
