import React, { useRef, useState } from 'react';
import { FaStar } from "react-icons/fa";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import "./testimonial.css"
function Testimonialtwo() {
  return (
    <>
    <section className="testimonial-area about-mar">
     
      <div
        className="section section-padding bg-img overly-style-1 opacity-point-7"
        // style={{
        //   backgroundImage:
        //     "url(https://htmldemo.net/drior/drior/assets/images/bg/bg-1.jpg)",
        // }}
      >
        <div className="container">
        <div className="section-title text-center mb-lg-9 mb-md-7 mb-5">
      <h2 className="title white ">
        WHAT <span> CLIENTS</span> SAY?
      </h2>
      <p className="white">
      Clients praise our creative designs, successful projects, and consistently outstanding customer service.
      </p>
    </div>
          <div className="testimonial-active swiper swiper-initialized swiper-horizontal swiper-pointer-events">
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-a4481288903ee2ec"
              aria-live="polite"
            >
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1000: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="Swiper-con">
                    <div
                      className="swiper-slide swiper-slide-duplicate"
                      data-swiper-slide-index={2}
                      role="group"
                      aria-label="3 / 4"
                      // style={{ width: 370 }}
                    >
                      <div className="single-testimonial-wrapper">
                        <div className="testimonial-img-title">
                          <div className="testimonial-img">
                            <img
                              src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png"
                              alt="testimonial"
                            />
                          </div>
                          <div className="testimonial-title">
                            <h4>Raj Saxena</h4>
                            {/* <span>CEO &amp; Founder </span> */}
                          </div>
                        </div>
                        <p>
                        Good Ambiance and a great environment for learning and growth. So far I have noticed the team environment is healthy and approachable. Good Opportunity for fresher and experience.
                        </p>
                        <div className="quote">
                          <i className="fa fa-quote-left" />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{  width: 370 }}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Lata Kalugade</h4>
                          {/* <span>CEO &amp; Founder </span> */}
                        </div>
                      </div>
                      <p>
                      They are very good with their services . Their backhand team is quite friendly and they willing to help me whenever i approach them.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>
                  {" "}
                </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{  width: 370 }}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Prathamesh Mhatre</h4>
                          {/* <span>CEO &amp; Founder </span> */}
                        </div>
                      </div>
                      <p>
                      It was a great experience with you guys, You work great and punctuality is great too ,all thanx to you guys.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{ width: 370}}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Divya Krishna</h4>
                          {/* <span>CEO &amp; Founder </span> */}
                        </div>
                      </div>
                      <p>
                      
                      The work was done in a very professional manner. The finishing was good. Promised design and material was delivered. After sales support was also good.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{ width: 370}}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Lata Kalugade</h4>
                          {/* <span>CEO &amp; Founder </span> */}
                        </div>
                      </div>
                      <p>
                      I recently used the Digital Business Soltuions for my business website and was very pleased with the results.
                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="Swiper-con">
                  <div
                    className="swiper-slide swiper-slide-duplicate"
                    data-swiper-slide-index={2}
                    role="group"
                    aria-label="3 / 4"
                    // style={{  width: 370}}
                  >
                    <div className="single-testimonial-wrapper">
                      <div className="testimonial-img-title">
                        <div className="testimonial-img">
                          <img
                            src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png"
                            alt="testimonial"
                          />
                        </div>
                        <div className="testimonial-title">
                          <h4>Bhagyashree Minocha</h4>
                          {/* <span>CEO &amp; Founder </span> */}
                        </div>
                      </div>
                      <p>
                        Beyond Techs Soltions Helped to grow my business                      </p>
                      <div className="quote">
                        <i className="fa fa-quote-left" />
                      </div>
                    </div>
                  </div>{" "}
                  </div>
                </SwiperSlide>
              </Swiper>
              
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            />
          </div>
        </div>
      </div>
     
    </section>

</>
  )
}

export default Testimonialtwo
