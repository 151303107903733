import React from 'react'
import "./Header.css"
import logo from "../../assets/beyondtechsnobglogo.png"
import { FaWhatsapp } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";





function Navigatio() {
  return (
    <>
      <header className="headerSection">
  <nav className="navbar navbar-expand-lg">
    <div className="container">
      <div className="navbar-socialLinks d-none d-md-block" id="socialId">
        <ul>
          <li>
            {/* <a href="https://www.facebook.com/search/top?q=shree%20krishna%20digital%20marketing"> */}
            <a>
              <i className="fa fa-facebook" aria-hidden="true"><FaFacebookF /></i>
            </a>
          </li>
          <li>
            {/* <a href="https://www.instagram.com/shree.krishnadigitalmarketing/?igshid=YmMyMTA2M2Y%3D"> */}
            <a>
              <i className="fa fa-instagram" aria-hidden="true"><FaInstagram /></i>
            </a>
          </li>
          <li>
            {/* <a href="https://www.linkedin.com/company/shree-krishna-digital-marketing/"> */}
            <a>
              <i className="fa fa-linkedin" aria-hidden="true" ><CiLinkedin /></i>
            </a>
          </li>
          <li>
            <a href="https://wa.me/+919167300235">
              <i className="fa fa-whatsapp" aria-hidden="true" ><FaWhatsapp /></i>
            </a>
          </li>
        </ul>
      </div>
      <a aria-current="page" className="navbar-brand active" href="/">
        <img
          className="logo1"
          src={logo}
          alt="company logo"
        />
      </a>
      <div>
        {/* <button className="navbar-toggler" type="button"> */}
          {/* <span className="navbar-toggler-icon" /> */}
          <div className='bur'>
          <i><GiHamburgerMenu /></i>
          </div>
          
        {/* </button> */}
        <div
          className="PrivateSwipeArea-root css-x15wq9"
          style={{ width: 20 }}
        />
      </div>
    </div>
  </nav>

</header>

    </>
  )
}

export default Navigatio
