import React from 'react'
import "./pro.css"
function Process() {
  return (
    <>
      <section className="border-top" id="defaultProcess">
        <div className="container">
  <div className="titleSection mb-5">
    <span>Process</span>
    <h2>Our working process</h2>
    <p>
      We Follow The Best Work Process In Order To Give Our Clients Best Results
      And Sales.
    </p>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-12 pt-lg-5">
        <div className="row align-items-end">
          <div className="col-lg-6 order-lg-2 text-center">
            <div
              className="animate__animated animate__fadeInLeftBig"
              style={{ animationDuration: "1s" }}
            >
              <img
                className="img-fluid"
                src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-01.svg"
                alt="working process"
                width={400}
              />
            </div>
          </div>
          <div className="col-lg-6 border-start border-bottom pb-lg-5 mt-5 mb-5 ">
            <div
              className="animate__animated animate__fadeInUpBig"
              style={{ animationDuration: "1s" }}
            >
              <div className="process-item ms-5 ms-sm-8 ms-lg-5">
                <span className="process-item-number">01</span>
                <h3>Plan</h3>
                <p>
                  We set priorities, organize content, and understand the
                  buyer’s journey that your audience takes as they navigate your
                  website. At the end of strategy, you will have a Blueprint for
                  your website project, a comprehensive strategic plan for your
                  website design, content, and functionality.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 border-end border-bottom text-center py-lg-5">
            <div
              className="animate__animated animate__fadeInRightBig"
              style={{ animationDuration: "1s" }}
            >
              <img
                className="img-fluid"
                src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-02.svg"
                alt="working process"
                width={400}
              />
            </div>
          </div>
          <div className="col-lg-6 pb-lg-5 mt-5 mb-5">
            <div
              className="animate__animated animate__fadeInUpBig"
              style={{ animationDuration: "1s" }}
            >
              <div className="process-item ms-5 ms-sm-8 ms-lg-6">
                <span className="process-item-number">02</span>
                <h3>Design</h3>
                <p>
                  Once the Blueprint is approved, we create wireframes and a
                  design comp for your review. This process involves various
                  reviews, approvals, and close communication between you and
                  our creative team. We begin with the Homepage to set style,
                  image and branding standards, then move toward interior
                  layouts.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 order-lg-2 text-center align-self-center">
            <div
              className="animate__animated animate__fadeInLeftBig"
              style={{ animationDuration: "1s" }}
            >
              <img
                className="img-fluid"
                src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-03.svg"
                alt="working process"
                width={400}
              />
            </div>
          </div>
          <div className="col-lg-6 border-start border-bottom pb-lg-5 mt-5 mb-5">
            <div
              className="animate__animated animate__fadeInUpBig"
              style={{ animationDuration: "1s" }}
            >
              <div className="process-item ms-5 ms-sm-8 ms-lg-6">
                <span className="process-item-number">03</span>
                <h3>Develop</h3>
                <p>
                  With the blueprint &amp; design comps as our guide, the
                  development team begins to create your website with the state
                  of the earth tools. Our entire team works to add content,
                  final design elements, review, &amp; test your website for
                  quality.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-1">
          <div className="col-lg-6 border-end text-center py-8">
            <div
              className="animate__animated animate__fadeInRightBig"
              style={{ animationDuration: "1s" }}
            >
              <img
                className="img-fluid"
                src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-04.svg"
                alt="working process"
                width={400}
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center pb-lg-5 mt-5 mb-5">
            <div
              className="animate__animated animate__fadeInUpBig"
              style={{ animationDuration: "1s" }}
            >
              <div className="process-item process-item-last ms-5 ms-sm-8 ms-lg-6">
                <span className="process-item-number">04</span>
                <h3>Deploy</h3>
                <p>
                  After testing and review, we present your new website. Upon
                  your approval, your website will be launched, promoted and
                  optimized for search engines such as Google &amp; Bing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>

    </>
  )
}

export default Process
